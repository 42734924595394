@import '../../variables.module.scss';

.auth{
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100vh;
    background-color: $primary-light-color;
    .logo{
        img {
            display: block;
            width: 200px;
            margin: 0 auto 30px;
        }
    }
    h1{
         font-size: 18px;
         color: $primary-text-color;
         padding: 0;
         margin: 0 0 30px;
         small{
             font-weight: 400;
             margin: 8px 0 0;
         }
    }
}