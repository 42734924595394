$primary-color: #6031F2;
$primary-light-color: #E7E1FA;
$primary-lighter-color: #F8F8FF;
$secondary-color: #E87A25;
$error-color: #F50044;
$success-color: #28A645;
$yellow-color: #F0A947;
$white-color: #fff;
$bg-light-color: #fafafa;
$border-color: #E1E2EF;
$primary-text-color: #000000;
$font-family: 'Noto Sans', sans-serif;
$regular-font-size: 16px;

:export {
    primaryColor: $primary-color;
    primaryLightColor: $primary-light-color;
    bgLightColor: $bg-light-color;
    primaryTextColor: $primary-text-color;
    secondaryColor: $secondary-color;
    whiteColor: $white-color;
    fontFamily: $font-family;
    errorColor: $error-color;
    successColor: $success-color;
    primaryLighterColor: $primary-lighter-color
}