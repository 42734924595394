@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import './variables.module.scss';


$calendar-col-height: 100px;

html,
body {
  background-color: $primary-light-color;
}

progress::-webkit-progress-bar {
  background-color: #eeeeee;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.01) inset;
  height: 6px;
}

progress::-webkit-progress-value {
  background-color: $primary-color !important;
  height: 6px;
}


/* For Firefox */
progress {
  background-color: #eee;
  height: 6px;
}

progress::-moz-progress-bar {
  background-color: $primary-color !important;
  height: 6px;
}

/* For IE10 */
progress {
  background-color: #eee;
  height: 6px;
}

progress {
  background-color: $primary-color;
  height: 6px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right!important
    /*  */
  ;
}

.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;

  .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.h-100 {
  height: 100%;
}

.mvh-100 {
  min-height: 100vh;
}

.mh-90vh {
  height: 90vh;
}


.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.flex-start {
  align-items: flex-start !important;
}

.align-center {
  align-items: center !important;
}

.no-wrap {
  white-space: nowrap;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom !important;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 1;
}

.text-primary {
  color: $primary-color;
}

.text-muted {
  color: rgba($color: $primary-text-color, $alpha: .50);
}

.text-success {
  color: $success-color !important;
}

.text-error {
  color: $error-color !important;
}

.text-secondary {
  color: $secondary-color;
}

.text-color-60 {
  color: rgba($color: $primary-text-color, $alpha: .60)
}

.w-break-all {
  word-break: break-all;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-3 {
  padding-top: 1rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.ml-3 {
  margin-left: 16px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.btn-rounded {
  border-radius: 30px !important;
  min-width: auto !important;
  width: 50px !important;
  height: 50px !important;
  line-height: 1 !important;
  padding: 0 !important;
  font-size: 16px !important;
}

.close-btn {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.bg-primary-light {
  background-color: rgba($color: $primary-color, $alpha: .15) !important;
}

.bg-success-light {
  background-color: rgba($color: $success-color, $alpha: .15) !important;
}

.bg-danger-light {
  background-color: rgba($color: $error-color, $alpha: .15) !important;
}

.bg-secondary-light {
  background-color: rgba($color: $secondary-color, $alpha: .15) !important;
}

.bg-primary-lighter {
  background-color: $primary-lighter-color;
}

.pageLoader {
  position: relative;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1200;
    width: 100%;
    min-width: 250px;
    height: 1000px;
    text-align: center;
    padding-top: 100px;
    background-color: #fff;
  }
}

.pageLoader {
  &.bg-primary-light {
    .loader {
      background-color: rgba($color: $primary-color, $alpha: .15) !important;
    }
  }

  &.h-auto {
    .loader {
      height: auto;
    }
  }
}

.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($color: $primary-text-color, $alpha: .60);
  z-index: 1300;
  color: $white-color;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  .book {
    --color: #fff;
    --duration: 6.8s;
    width: 32px;
    height: 12px;
    position: relative;
    margin: 32px 0 0 0;
    zoom: 1;
  }

  .book .inner {
    width: 32px;
    height: 12px;
    position: relative;
    transform-origin: 2px 2px;
    transform: rotateZ(-90deg);
    -webkit-animation: book var(--duration) ease infinite;
    animation: book var(--duration) ease infinite;
  }

  .book .inner .left,
  .book .inner .right {
    width: 60px;
    height: 4px;
    top: 0;
    border-radius: 2px;
    background: var(--color);
    position: absolute;
  }

  .book .inner .left:before,
  .book .inner .right:before {
    content: "";
    width: 48px;
    height: 4px;
    border-radius: 2px;
    background: inherit;
    position: absolute;
    top: -10px;
    left: 6px;
  }

  .book .inner .left {
    right: 28px;
    transform-origin: 58px 2px;
    transform: rotateZ(90deg);
    -webkit-animation: left var(--duration) ease infinite;
    animation: left var(--duration) ease infinite;
  }

  .book .inner .right {
    left: 36px;
    transform-origin: 2px 2px;
    transform: rotateZ(-90deg);
    -webkit-animation: right var(--duration) ease infinite;
    animation: right var(--duration) ease infinite;
  }

  .book .inner .middle {
    width: 32px;
    height: 12px;
    border: 4px solid var(--color);
    border-top: 0;
    border-radius: 0 0 9px 9px;
    transform: translateY(2px);
  }

  .book ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 50%;
    top: 0;
  }

  .book ul li {
    height: 4px;
    border-radius: 2px;
    transform-origin: 100% 2px;
    width: 48px;
    right: 0;
    top: -10px;
    position: absolute;
    background: var(--color);
    transform: rotateZ(0deg) translateX(-18px);
    -webkit-animation-duration: var(--duration);
    animation-duration: var(--duration);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .book ul li:nth-child(0) {
    -webkit-animation-name: page-0;
    animation-name: page-0;
  }

  .book ul li:nth-child(1) {
    -webkit-animation-name: page-1;
    animation-name: page-1;
  }

  .book ul li:nth-child(2) {
    -webkit-animation-name: page-2;
    animation-name: page-2;
  }

  .book ul li:nth-child(3) {
    -webkit-animation-name: page-3;
    animation-name: page-3;
  }

  .book ul li:nth-child(4) {
    -webkit-animation-name: page-4;
    animation-name: page-4;
  }

  .book ul li:nth-child(5) {
    -webkit-animation-name: page-5;
    animation-name: page-5;
  }

  .book ul li:nth-child(6) {
    -webkit-animation-name: page-6;
    animation-name: page-6;
  }

  .book ul li:nth-child(7) {
    -webkit-animation-name: page-7;
    animation-name: page-7;
  }

  .book ul li:nth-child(8) {
    -webkit-animation-name: page-8;
    animation-name: page-8;
  }

  .book ul li:nth-child(9) {
    -webkit-animation-name: page-9;
    animation-name: page-9;
  }

  .book ul li:nth-child(10) {
    -webkit-animation-name: page-10;
    animation-name: page-10;
  }

  .book ul li:nth-child(11) {
    -webkit-animation-name: page-11;
    animation-name: page-11;
  }

  .book ul li:nth-child(12) {
    -webkit-animation-name: page-12;
    animation-name: page-12;
  }

  .book ul li:nth-child(13) {
    -webkit-animation-name: page-13;
    animation-name: page-13;
  }

  .book ul li:nth-child(14) {
    -webkit-animation-name: page-14;
    animation-name: page-14;
  }

  .book ul li:nth-child(15) {
    -webkit-animation-name: page-15;
    animation-name: page-15;
  }

  .book ul li:nth-child(16) {
    -webkit-animation-name: page-16;
    animation-name: page-16;
  }

  .book ul li:nth-child(17) {
    -webkit-animation-name: page-17;
    animation-name: page-17;
  }

  .book ul li:nth-child(18) {
    -webkit-animation-name: page-18;
    animation-name: page-18;
  }

  @-webkit-keyframes page-0 {
    4% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    13%,
    54% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    63% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-0 {
    4% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    13%,
    54% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    63% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-1 {
    5.86% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    14.74%,
    55.86% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    64.74% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-1 {
    5.86% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    14.74%,
    55.86% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    64.74% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-2 {
    7.72% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    16.48%,
    57.72% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    66.48% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-2 {
    7.72% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    16.48%,
    57.72% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    66.48% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-3 {
    9.58% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    18.22%,
    59.58% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    68.22% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-3 {
    9.58% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    18.22%,
    59.58% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    68.22% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-4 {
    11.44% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    19.96%,
    61.44% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    69.96% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-4 {
    11.44% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    19.96%,
    61.44% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    69.96% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-5 {
    13.3% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    21.7%,
    63.3% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    71.7% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-5 {
    13.3% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    21.7%,
    63.3% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    71.7% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-6 {
    15.16% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    23.44%,
    65.16% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    73.44% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-6 {
    15.16% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    23.44%,
    65.16% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    73.44% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-7 {
    17.02% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    25.18%,
    67.02% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    75.18% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-7 {
    17.02% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    25.18%,
    67.02% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    75.18% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-8 {
    18.88% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    26.92%,
    68.88% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    76.92% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-8 {
    18.88% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    26.92%,
    68.88% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    76.92% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-9 {
    20.74% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    28.66%,
    70.74% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    78.66% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-9 {
    20.74% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    28.66%,
    70.74% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    78.66% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-10 {
    22.6% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    30.4%,
    72.6% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    80.4% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-10 {
    22.6% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    30.4%,
    72.6% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    80.4% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-11 {
    24.46% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    32.14%,
    74.46% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    82.14% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-11 {
    24.46% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    32.14%,
    74.46% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    82.14% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-12 {
    26.32% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    33.88%,
    76.32% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    83.88% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-12 {
    26.32% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    33.88%,
    76.32% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    83.88% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-13 {
    28.18% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    35.62%,
    78.18% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    85.62% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-13 {
    28.18% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    35.62%,
    78.18% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    85.62% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-14 {
    30.04% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    37.36%,
    80.04% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    87.36% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-14 {
    30.04% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    37.36%,
    80.04% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    87.36% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-15 {
    31.9% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    39.1%,
    81.9% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    89.1% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-15 {
    31.9% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    39.1%,
    81.9% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    89.1% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-16 {
    33.76% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    40.84%,
    83.76% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    90.84% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-16 {
    33.76% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    40.84%,
    83.76% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    90.84% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-17 {
    35.62% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    42.58%,
    85.62% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    92.58% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-17 {
    35.62% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    42.58%,
    85.62% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    92.58% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes page-18 {
    37.48% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    44.32%,
    87.48% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    94.32% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @keyframes page-18 {
    37.48% {
      transform: rotateZ(0deg) translateX(-18px);
    }

    44.32%,
    87.48% {
      transform: rotateZ(180deg) translateX(-18px);
    }

    94.32% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }

  @-webkit-keyframes left {
    4% {
      transform: rotateZ(90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
    }

    46%,
    54% {
      transform: rotateZ(90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
    }

    96% {
      transform: rotateZ(90deg);
    }
  }

  @keyframes left {
    4% {
      transform: rotateZ(90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
    }

    46%,
    54% {
      transform: rotateZ(90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
    }

    96% {
      transform: rotateZ(90deg);
    }
  }

  @-webkit-keyframes right {
    4% {
      transform: rotateZ(-90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
    }

    46%,
    54% {
      transform: rotateZ(-90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
    }

    96% {
      transform: rotateZ(-90deg);
    }
  }

  @keyframes right {
    4% {
      transform: rotateZ(-90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
    }

    46%,
    54% {
      transform: rotateZ(-90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
    }

    96% {
      transform: rotateZ(-90deg);
    }
  }

  @-webkit-keyframes book {
    4% {
      transform: rotateZ(-90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
    }

    40.01%,
    59.99% {
      transform-origin: 30px 2px;
    }

    46%,
    54% {
      transform: rotateZ(90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
    }

    96% {
      transform: rotateZ(-90deg);
    }
  }

  @keyframes book {
    4% {
      transform: rotateZ(-90deg);
    }

    10%,
    40% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
    }

    40.01%,
    59.99% {
      transform-origin: 30px 2px;
    }

    46%,
    54% {
      transform: rotateZ(90deg);
    }

    60%,
    90% {
      transform: rotateZ(0deg);
      transform-origin: 2px 2px;
    }

    96% {
      transform: rotateZ(-90deg);
    }
  }

}

/* loader */

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.MuiDataGrid-panel {
  right: 0 !important;
  left: auto !important;
  transform: translate3d(-30px, 210px, 0px) !important;
}

.MuiDataGrid-root {
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeaderTitle {
        font-weight: 600;
      }
    }
  }
}
table{
  tr{
    th{
      font-weight: 600!important;
    }
  }
}
body {
  font-family: $font-family;
  color: $primary-text-color;
}

.user-dropdownMenu {
  a {
    display: block;
    white-space: nowrap;
    text-decoration: none;
    color: $primary-text-color;

    p {
      display: inline-block;
    }
  }
}

.otpContainer {
  flex: 1 0;
  padding: 0 8px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

input.otpInput {
  width: 100% !important;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #0000003b;
  font-size: 16px;
}

.form-title {
  font-size: 16px;
  font-weight: 600;
  color: $primary-text-color;
  margin: 30px 0 16px;
}

.section-title {
  font-size: 18px;
  font-weight: 400;
  color: $primary-text-color;
  margin: 16px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    height: 1px;
    width: 100%;
    background-color: rgba($color: $primary-color, $alpha: .20);
    margin-left: 16px;
  }
}

.scrollable-form-card {
  max-height: calc(100vh - 164px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .card-head {
    padding: 16px;
    flex-shrink: 0;
  }

  .card-body {
    padding: 0;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

/* scrollable card */

.main {
  padding: 50px 16px 16px 80px;
  background-color: $primary-light-color;
  min-height: 91vh;

  &.pl-3 {
    padding-left: 16px;
  }

  .main-child {
    padding: 16px 0 0;
    border-radius: 16px;
    min-height: calc(100vh - 98px);

    .MuiAutocomplete-root,
    .MuiOutlinedInput-root {
      background-color: $white-color;
    }

    .searchBar {
      .ri-search-line {
        background-color: $white-color;
        height: 46px;
      }
    }
  }

  header {
    background-color: $white-color !important;

    nav {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      flex-direction: row;
      flex-wrap: wrap;

      a {
        display: inline-block;
        text-decoration: none;
        color: #fff;
        padding: 0 16px;
      }
    }

    .user-dropdown {
      font-size: $regular-font-size;
      color: $primary-text-color;

      .user-ic {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 8px 0 0;
      }
    }
  }

  /* app bar */
  .sideBar {
    position: fixed;
    width: 65px;
    left: 0;
    top: 10px;
    bottom: 10px;
    height: calc(100vh - 35px);
    background-color: $primary-color;
    border-radius: 0 24px 24px 0;
    padding: 16px 0 0 0;
    box-shadow: 5px 3px 6px 1px rgba($color: #000000, $alpha: .16);
    overflow: hidden;
    transition: all .4s ease-in;
    z-index: 1210;

    &:hover {
      width: 200px;

      a.active {
        &::after {
          width: 180px !important;
        }
      }
    }

    .logo {
      text-align: center;
      margin: 0 0 30px;

      img {
        width: 50px;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        position: relative;
        margin: 4px 0;

        a {
          display: block;
          white-space: nowrap;
          text-decoration: none;
          color: $white-color;
          padding: 0 8px 0 8px;

          &.active {
            &::after {
              content: '';
              display: block;
              width: 55px;
              height: 100%;
              background-color: rgba($color: $white-color, $alpha: .16);
              position: absolute;
              top: 0;
              left: 4px;
              border-radius: 20px;
              border-bottom-right-radius: 0;
              transition: all .3s ease-in;
            }
          }

          div {
            vertical-align: middle;
            display: inline-block;

            img {
              width: 50px;
            }
          }
        }
      }
    }

    .item-list {
      height: calc(100% - 140px);
    }

    ul:not(.item-list) {
      margin: 0;
      padding: 0;
    }
  }

  .breadcrum-menu {
    padding: 0;
    margin: 0 0 30px;
    z-index: 1200;

    a {
      text-decoration: none;

      i {
        font-size: 20px;
        line-height: 1;
      }
    }

    p {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: $primary-color;
    }
  }

  .MuiButton-root {
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    padding: 6px 20px;
    font-size: 12px;
  }

  .MuiButton-outlined {
    background-color: $white-color;
  }

  .icon-button {
    font-size: 20px;
    line-height: 1;
    box-shadow: none;
  }

  .action-IconButton{
    background-color: $white-color;
  }

  .no-shadow {
    box-shadow: none;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    min-width: 60px;
    max-width: 300px;
  }

  .colSelect {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      min-width: 60px;
    }
  }

  .searchBar {
    fieldset {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ri-search-line {
      display: flex;
      width: 30px;
      height: 54px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-left: 0;
      align-items: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .MuiFormControl-root {
      margin: 0;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .MuiFormControl-root {
    margin: 0 0 16px;
    width: 100%;

    .MuiFormControl-root {
      margin: 0;
    }

    .MuiSelect-select,
    .MuiOutlinedInput-input {
      height: 1em;
      min-height: auto;
      padding: 16px 14px;
      line-height: 1;
    }

    .search-select {
      .MuiChip-root {
        width: auto;
        background-color: transparent;
        margin: 0 4px 0 0;

        &:not(last-child) {
          span {
            &::after {
              content: ","
            }
          }
        }

        span {
          padding: 0;
          font-size: 16px;
          color: $primary-text-color;
        }

        svg {
          display: none;
        }
      }

      .MuiAutocomplete-tag {
        color: rgba($color: $primary-text-color, $alpha: .5)
      }
    }

    .MuiInputLabel-root {
      line-height: 1.5;
      height: auto;
    }

    .MuiListItemText-root {
      margin: 0;
    }
  }

  .profilePic-selector {
    position: relative;
    margin: 0 0 50px;
    padding: 0;
    text-align: center;

    .MuiAvatar-root {
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }

    button:not(.rm-btn) {
      min-width: auto;
      width: 40px;
      height: 40px;
      padding: 0;
      overflow: hidden;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 20px);

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        width: 100%;
      }
    }
  }

  /* image-picker */
  table {
    th {
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .MuiDataGrid-toolbarContainer {
    justify-content: end;
  }

  // .MuiDataGrid-row,
  // .MuiDataGrid-cell{
  //   max-height: fit-content!important;
  //   line-height: 1;
  // }
  // .MuiDataGrid-cell{
  //   padding: 10px!important;
  // }

  /* table */
  .error {
    color: $error-color;
    margin: 4px 0 0;
  }

  .MuiAvatar-root {
    text-transform: uppercase;
  }

  .MuiDataGrid-toolbarContainer {
    button {
      box-shadow: none;
    }
  }

  .MuiStepper-root {
    .MuiStepLabel-label {
      font-weight: 600;

      &.Mui-active {
        color: $primary-color;
      }
    }
  }

  .tab-content {
    padding: 16px 0 0;
    margin: 0 0;
  }

  .MuiTabs-root {
    .MuiButtonBase-root {
      font-weight: 600;
      font-size: 14px;
      text-transform: inherit;
      border-radius: 4px;
      margin: 0 16px 0 0;
    }
  }

  .box-tabs {
    .MuiButtonBase-root {
      min-width: auto !important;
      color: $yellow-color;
      border: 1px solid $border-color;
    }

    .Mui-selected {
      background-color: $yellow-color;
      color: $primary-text-color;
      border-color: $yellow-color;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  .pData {
    font-size: 16px;
    color: $primary-text-color;
    margin: 0 0 16px;

    label {
      display: block;
      font-size: 14px;
      color: rgba($color: $primary-text-color, $alpha: .60);
      margin: 0 0 4px;
    }
  }

  .btnFixed {
    position: fixed;
    right: 0;
    left: 60px;
    bottom: 0;
    padding: 16px;
    background-color: $white-color;
    z-index: 1200;
    box-shadow: 10px 0px 10px 1px rgba($color: $primary-text-color, $alpha: .1);
  }

  .subjectAccordion {
    .MuiAccordionSummary-content {
      justify-content: space-between;
      align-items: center;
    }
  }

  .bordered-list {
    li:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  .collapseRow {
    padding-top: 0;
    padding-bottom: 0;
    background-color: $bg-light-color;
  }

  .termCol-dt-card {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: $primary-text-color, $alpha: .60);
    box-shadow: none;
    border: 0;
    line-height: 1;
    background-color: transparent;

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: $primary-text-color;
      padding: 0;
      margin: 0 0 16px;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: $primary-text-color;
      padding: 0;
      margin: 0 0 8px;
    }
  }

  .classDatePicker {
    display: inline-block;
    padding: 0 0;
    position: relative;
    font-size: 16px;
    border: 1px solid $primary-light-color;
    border-radius: 6px;
    text-align: center;
    max-width: 128px;

    small {
      color: rgba($color: $primary-text-color, $alpha: .60);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
    }

    .calIcon {
      display: block;
      padding: 0 4px;
      background-color: $primary-light-color;
      color: $primary-color;
      text-align: center;

      .MuiInputAdornment-root {
        height: auto;
        max-height: inherit;
        margin-left: 0;
        width: 40px;

        button {
          color: $primary-color;
        }
      }

      .MuiFormControl-root {
        margin: 0;
        border: 0;

        .MuiOutlinedInput-root {
          background-color: transparent;
          padding: 0;
          justify-content: center;
        }

        input {
          width: 0;
          padding: 0;
        }

        fieldset {
          border: 0;
        }
      }
    }
  }

  .weekTab {
    display: inline-block;
    background-color: $primary-light-color;
    padding: 2px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 auto;
    color: $primary-color;
    font-weight: 400;
  }

  .profile-card {
    position: relative;
    padding-top: 80px;

    .profile-bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 150px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .profileDt-card {
    .card-ic {
      font-size: 30px;
      opacity: 0.6;
    }
  }

  .feeCard {
    position: relative;

    .leftCard {
      width: 40%;
      position: relative;
      background-color: $white-color;
      background-size: cover;
      background-position: center;
      padding: 30px 20% 30px 30px;
      min-height: 200px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 2px rgba($color: $primary-text-color, $alpha: .1);

      h3 {
        font-size: 18px;
        color: $primary-color;
        margin: 0 0 16px;
        padding: 0;
        font-weight: 600;
        line-height: 1;
      }

      h1 {
        font-size: 26px;
        padding: 0;
        margin: 0;
        color: $primary-text-color;
        font-weight: 600;

        small {
          display: block;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    /* left */
    .rightCard {
      background-color: $primary-color;
      padding: 16px;
      background-size: cover;
      background-position: center;
      position: absolute;
      right: 0;
      box-shadow: 0px 0px 14px 4px rgba($color: $primary-text-color, $alpha: .2);
      width: 60%;
      height: 70%;
      top: 8%;
      border-radius: 10px;

      img {
        width: 50px;
        opacity: 0.8;
      }

      p {
        font-size: 18px;
        color: $white-color;
        font-weight: 600;
      }

      .pData {
        color: rgba($color: $white-color, $alpha: .8);

        label {
          color: rgba($color: $white-color, $alpha: .5);
        }
      }
    }
  }

  .classWaiting {
    .card {
      background-size: 100%;
      background-position: left bottom;
      background-repeat: no-repeat;
      width: 100%;

      .MuiGrid-container {
        min-height: calc(95vh - (24px * 3) - 70px);
      }

      h1 {
        font-size: 34px;
        color: $success-color;
        padding: 0;
        margin: 0;
      }

      h2 {
        font-size: 26px;
        padding: 0;
        margin: 16px 0 8px;

        small {
          font-size: 60%;
          margin: 16px 0 0;
        }
      }

      h3 {
        font-size: 20px;
        margin: 0;
        padding: 0;
      }

      .waitingImg {
        display: block;
        min-width: 240px;
        max-width: 80%;
        margin: 0 auto;
      }

      .data {
        padding: 24px;
        background-color: $white-color;

        .pData {
          margin: 0 24px;

          label {
            display: inline-block;
            margin: 0 8px 0 0;
          }
        }
      }
    }

    /* card */
  }

  .liveClass {

    whereby-embed,
    iframe {
      width: 100vw;
      height: 100vh;
      border: 0;
      margin: 0;
    }

    .classInfo {
      position: fixed;
      top: 0;
      left: 16%;
      width: 60%;
      padding: 10px 24px;
      opacity: 0;
      background-color: rgba($color: $white-color, $alpha: .6);

      &:hover {
        opacity: 1;
      }

    }

    h1 {
      color: $primary-text-color;
      font-size: 18px;
      margin: 0 0 8px;
      font-weight: 400;
    }

    h2 {
      font-size: 14px;
      color: rgba($color: $primary-text-color, $alpha: .60);
      margin: 0 0 16px;
      padding: 0;
      font-weight: 600;
    }

    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba($color: $primary-text-color, $alpha: .62);
      margin-bottom: 10px;
    }

    p {
      i {
        font-size: 18px;
        display: inline-block;
        transform: translateY(-2px);
      }

      .MuiBadge-root {
        background-color: rgba($color: $primary-text-color, $alpha: 0.6);
      }
    }
  }

  .noData {
    display: block;
    width: 100%;
    padding: 90px 24px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    opacity: 0.6;
  }

  .contact-us {
    .h-100 {
      min-height: calc(100vh - 100px);
    }

    .MuiPaper-root {
      background-position: right bottom;
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 60px 30px;
    }
  }

  .mui-readonly {
    fieldset {
      border: 0;
    }
  }

  .week-selector {
    position: relative;

    button {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: calc(100% - 16px);
      box-shadow: none;
      opacity: 0;
    }
  }
  .enrollment-card{
    padding: 1rem;
    position: relative;
    h1{
      font-size: 16px;
      color: $primary-text-color;
      padding: 0;
      margin: 0 0 16px;
    }
    .actButton{
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .status{
      display: block;
      margin: 0 0 8px;
      font-size: 14px;
    }
  }
}

/* main */
.classCard {
  position: relative;
  border-radius: 6px;

  .demoClsLabel {
    display: inline-block;
    width: 100px;
    height: 60px;
    background-color: $secondary-color;
    position: absolute;
    top: -45px;
    left: -45px;
    transform: rotate(-45deg);
  }

  .attendance {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    color: $white-color;
    line-height: 1;
    padding: 4px 6px;
    border-bottom-left-radius: 6px;

    &.error-badge {
      background-color: $error-color;
    }

    &.success-badge {
      background-color: $success-color;
    }

    &.warning-badge {
      background-color: $secondary-color;
    }
  }

  .calendarBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 50px;
    line-height: 1;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 18px;
  }

  h1 {
    color: $primary-text-color;
    font-size: 18px;
    margin: 0 0 16px;
    font-weight: 400;
  }

  h2 {
    font-size: 14px;
    color: rgba($color: $primary-text-color, $alpha: .60);
    margin: 0 0 16px;
    padding: 0;
    font-weight: 600;

    .MuiBadge-root {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: rgba($color: $primary-text-color, $alpha: 0.5);
      margin: 0 4px;
    }
  }

  .bookedBox {
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  .prevBatchBox {
    color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  .availabelBox {
    color: $success-color;
    border: 1px solid $success-color;
  }

  .checkedBox {
    color: $border-color;
    border: 1px solid $border-color;
  }

  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba($color: $primary-text-color, $alpha: .62);
  }

  p {
    i {
      font-size: 18px;
      display: inline-block;
      transform: translateY(-2px);
    }
  }
}

.notification-pop .MuiPaper-root {
  ul {
    li:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }
}

.select-menu {
  max-height: 500px !important;
}

.MuiMenu-list{
  li{
    a{
      display: block;
      width: 100%;
    }
  }
}
.modal-Footer{
  .MuiDialogContent-root{
    padding-bottom: 75px;
  }
  .btnFixed{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    padding: 16px;
    text-align: center;
    box-shadow: 3px 0px 3px 1px rgba(0, 0, 0, 0.1);
    margin:0;
  }
}